/**
 * Site header
 */
.site-header {
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;
    background-color: #515865;

    .menu-icon {
        display: none;
        @include media-query($on-palm) {
            display: block;
            float: left;
            width: 36px;
            height: 26px;
            position: relative;
            top: 9px;
            margin-right: 10px;
            padding-top: 10px;
            text-align: center;

                > svg {
                    width: 18px;
                    height: 15px;

                    path {
                        fill: #fff;

                }
            }
        }
    }
}
.exp .menu-icon::before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}
.site-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;

    &,
    &:visited {
        color: $background-color;
    }
}
.site-nav {
    float: right;
    line-height: 56px;

    .page-link {
        color: $background-color;
        line-height: $base-line-height;
        font-weight: 700;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
            @include media-query($on-palm) {
                color: $text-color;
            }
    }

    @include media-query($on-palm) {
        position: absolute;
        width: 200px;
        height: 100%;
        top: 0;
        left: -200px;
        z-index: 7;
        background-color: #515865;

        .trigger {
            clear: both;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 15px 25px;
            color: #fff;
            border-bottom: 2px solid #5D6471;

            &:not(:last-child) {
                margin-right: 0;
            }
            &:hover{
                background-color: #5D6471;
            }
        }
    }
}


/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}
.copy {
    float: left;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
    h2{
      margin: 0;
    }
    .description{
      padding: 12px 0;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
    text-align: center;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}
.pagination, .post-navigation{
  a{
    padding: 5px 10px;
    border: 1px solid #ddd;
  }
    .prev{
        float: left;
    }
    .next{
        float: right;
    }
}
