.archive-header {
    width: 100%;
    overflow: hidden;
    h2 {
        float: left;
    }
    .icon {
        float: right;
        padding: 5px 10px;
        border: 1px solid #ddd;
        svg {
            width: 20px;
            height: 17px;
        }
    }

}
.archive-lists {
    list-style: none;
    margin: 0;
    h3 {
        margin: 0;
        float: left;
    }
    .by-year {
        padding: 9px 0;
        li {
            padding: 2px 0 5px 50px;
            .date {
                padding: 0 5px;
            }
        }
    }
}
.toggle-search{
    cursor: pointer;
}
.search {
    position: absolute;
    width: 100%;
    left: 0;
    background: rgba(75, 112, 24, 0.04);
    top: 0px;
    height: 100vh;
    z-index: 1;
    display: none;
    &.open{
        display: block;
        .icon {
            border-radius: 4px;
            position: absolute;
            top: 0;
            right: 0;
            svg{
                width: 40px;
                height: 40px;
            }
        }
    }

    .wrapper{
        background: #fff;
        padding: 30px;
        top:70px;
        position: relative;
        z-index: 7;
        border-radius: 3px;
    }
}
.search-input {
    width: calc(100% - 50px);
    height: 30px;
    border: 0;
    padding: 2px 25px;
    outline: none;
    margin-bottom: 20px;
    font-size: 1.3em;
    background: transparent;
    border-bottom: 3px solid #ccc;
}
#results-container {
    margin: 0;
    li {
        margin-left: 30px;
    }
}
span.last-update {
    font-size: 12px;
    padding-top: 30px;
    float: right;
}
